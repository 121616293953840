import { Button, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { getButtonActionByActionType, getVariantByActionStyle } from '@vfit/shared/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import {
  BackgroundImageContainer,
  CtaContainer,
  FullBannerTextContainer,
  ImageContainer,
} from './fullBanner.style';
import { IFullBanner } from './fullBanner.model';

const FullBanner = ({
  title,
  icon,
  iconSize,
  imageBg,
  imageBgMobile,
  gradient,
  action,
  customTracking,
  actionNavigationUrl,
  disableMarginBottom,
}: IFullBanner) => {
  const { isDesktop, isMobile } = useDeviceType();
  const { push } = useRouter();
  const queryClient = useQueryClient();

  return (
    <FullBannerTextContainer gradient={gradient} disableMarginBottom={disableMarginBottom}>
      <BackgroundImageContainer>
        {imageBg && !gradient && (
          <ImageAtoms
            nameCard="fullBannerBg"
            image={imageBg}
            imageMobile={imageBgMobile}
            isMobile={isMobile}
          />
        )}
      </BackgroundImageContainer>
      {icon && (
        <ImageContainer iconSize={iconSize}>
          <ImageAtoms nameCard="iconFullBannerImage" image={icon} />
        </ImageContainer>
      )}
      <CustomText
        size={isDesktop ? 44 : 32}
        sizeMobile={32}
        lineHeight={isDesktop ? 52 : 40}
        lineHeightMobile={40}
        margin={[0, 'auto']}
        text={title}
        textAlign="center"
      />
      {action?.title && action?.type && (
        <CtaContainer>
          <Button
            onClick={() => {
              customTracking?.();
              // actionClick is inhibited to avoid double url opening
              !actionNavigationUrl && getButtonActionByActionType(action, push, queryClient);
            }}
            href={actionNavigationUrl}
            target={actionNavigationUrl && action?.isBlank === 'true' ? '_blank' : undefined}
            name={`action_fullBanner_${action?.type?.toString() || ''}`}
            variant={getVariantByActionStyle(action?.style)}
          >
            {action?.title}
          </Button>
        </CtaContainer>
      )}
    </FullBannerTextContainer>
  );
};

export default FullBanner;
