import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const FullBannerTextContainer = styled.div<{
  gradient?: string;
  disableMarginBottom?: boolean;
}>`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: ${({ gradient }) => gradient || ''};
  font-family: ${fonts.regular};
  color: ${colors.$ffffff};
  margin: 45px 24px 120px;
  margin-bottom: ${({ disableMarginBottom }) => (disableMarginBottom ? '0px' : '120px')};
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 45px 32px 120px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 80px 65px;
    margin: 45px 80px 120px;
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    width: 1280px;
    margin: 45px auto 120px;
  }
`;

export const CtaContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  line-height: 0;

  @media (min-width: ${breakpoints.tablet}) {
    width: 328px;
    margin-top: 48px;
  }
`;

export const ImageContainer = styled.div<{ iconSize?: number }>`
  margin-bottom: 24px;
  height: ${(props) => `${props.iconSize ? props.iconSize : 75}px`};
  width: ${(props) => (props.iconSize ? `${props.iconSize}px` : 'auto')};
`;

export const BackgroundImageContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
`;
