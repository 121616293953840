import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';

export const InflationContainer = styled.div<{ compact?: boolean }>`
  align-items: center;
  display: flex;
  margin-top: ${(props) => (props.compact ? '0' : '8px')} !important;

  span {
    font-family: ${fonts.regular};
    ${pxToCssFont(12, 16)}
    font-style: normal;
    font-weight: 400;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(14, 18)}
    }
  }

  .tooltip {
    margin-top: ${(props) => (props.compact ? '0 !important' : '')};
    margin-left: ${(props) => (props.compact ? '0 !important' : '')};
  }
`;

export const DisclaimerContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0 !important;

  span {
    font-family: ${fonts.regular};
    ${pxToCssFont(12, 16)}
    font-style: normal;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(14, 18)}
    }
  }
`;

export const ProductCardSpecItemAction = styled.button`
  display: flex;
  border: none;
  padding: 0;
  margin: 0 0 0 16px !important;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${pxToCssFontSize(26)}
  font-family: ${fonts.light};

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const Title = styled.span<{ isGloballPromo?: boolean }>`
  p {
    margin: 0;
  }
  font-family: ${fonts.regular};
  font-style: normal;
  ${(isGloballPromo) =>
    isGloballPromo
      ? `
        ${pxToCssFont(17, 20)}
        font-weight: 700;
      `
      : `
        ${pxToCssFont(12, 16)}
        font-weight: 400;
    `}

  @media (min-width: ${breakpoints.desktop}) {
    ${(isGlobalPromo) => (isGlobalPromo ? '' : pxToCssFont(14, 18))}
  }
`;

export const Disclaimer = styled.span`
  p {
    margin: 0;
  }
  font-family: ${fonts.regular};
  ${pxToCssFont(12, 16)}
  font-style: normal;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(14, 18)}
  }
`;
