import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const VideoContainer = styled.div`
  height: 380px;
  overflow: hidden;
  position: relative;
  margin-bottom: 32px;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: 380px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 64px;

  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 620px;
    margin-left: 150px;
    margin-right: 150px;
  margin-bottom: 150px;


    iframe {
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin-left: 81px;
    }
  }
`;

export const Title = styled.div`
  div {
    margin-bottom: 32px;

    p {
      margin-block: 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 48px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin-top: 30px;
    }
  }
`;

