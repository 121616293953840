import React, { Children } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import * as S from './listItemsWithImage.style';
import { IList } from './listItemsWithImage.model';

const ListItemsWithImage = ({ textColor, elements, fontWeight }: IList) => {
  const getTextPurified = (text: string) => purify([text || ''])?.[0] || '';

  return (
    <S.ListContainer>
      {elements &&
        elements.length > 0 &&
        Children.toArray(
          elements.map((element) => (
            <S.ListElement>
              <S.IconContainer>
                <ImageAtoms image={element.icon} nameCard={element.text} />
              </S.IconContainer>
              <S.TextContainer>
                {element?.text && (
                  <S.Text
                    color={textColor}
                    fontWeight={fontWeight}
                    dangerouslySetInnerHTML={{ __html: getTextPurified(element?.text) }}
                  />
                )}
                <S.SubText
                  color={textColor}
                  dangerouslySetInnerHTML={{ __html: getTextPurified(element?.subtext) }}
                />
              </S.TextContainer>
            </S.ListElement>
          ))
        )}
    </S.ListContainer>
  );
};

export default ListItemsWithImage;
