import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const CardInfoProductContainer = styled.div`
  display: block;

  .swiper-wrapper {
    height: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 45px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 120px;
  }
`;

export const CardInfoProductContent = styled.div`
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  margin-top: 80px;
  div {
    margin: 0;
  }
  div p {
    margin: 0;
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;
