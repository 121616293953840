import React from 'react';
import { ImageDivider, CustomText, Button } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  AppMobile,
  purifyTextWithLogo,
  requireStaticImageAtomComponent,
} from '@vfit/shared/data-access';
import { ISplittedImageTextCard } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import {
  MainContainer,
  CardContainer,
  Info,
  DescriptionBox,
  Title,
  Description,
  CtaContainer,
  Container,
  ContainerImage,
  ImageElement,
} from './splittedImageTextCard.style';

const SplittedImageTextCard: React.FC<ISplittedImageTextCard> = ({
  topText,
  title,
  description,
  urlImg,
  action,
  info,
  divider,
  handleButtonClick,
  disableCoverImg,
}) => {
  const isApp = AppMobile.checkIsApp();
  const { isMobile } = useDeviceType();
  const [pTitle, pDescription] = purify([title, description], purifyTextWithLogo);
  const { src, srcSet } = requireStaticImageAtomComponent(urlImg || '');
  const isSmallAction = isMobile || !action?.url || action?.small;

  return (
    <Container data-name="splitted-image-text-card-container" isApp={isApp}>
      {topText && <CustomText text={topText || ''} />}
      <MainContainer>
        <CardContainer>
          <DescriptionBox>
            {pTitle && <Title dangerouslySetInnerHTML={{ __html: pTitle }} />}
            {pDescription && <Description dangerouslySetInnerHTML={{ __html: pDescription }} />}
            {action?.title && (
              <CtaContainer>
                <Button
                  variant="primary"
                  onClick={() => handleButtonClick?.()}
                  name={`action_splitted_${action?.type?.toString() || ''}`}
                  model={isSmallAction ? 'circle' : 'regular'}
                  svg={isSmallAction ? 'chevronRightBtn' : undefined}
                  size="medium"
                >
                  {action.title}
                </Button>
              </CtaContainer>
            )}
          </DescriptionBox>
          <ContainerImage style={{ display: 'flex' }} disableCoverImg={disableCoverImg}>
            <ImageElement style={{ objectFit: 'cover' }} alt="cta" src={src} srcSet={srcSet} />
          </ContainerImage>
        </CardContainer>
        <Info>{info && <CustomText text={info} />}</Info>
      </MainContainer>
      {divider?.image && (
        <ImageDivider
          image={divider.image}
          imageMobile={divider.imageMobile}
          isMobile={isMobile}
          alt={divider.text || ''}
        />
      )}
    </Container>
  );
};

export default SplittedImageTextCard;
