import { Children } from 'react';
import { purify } from '@vfit/shared/themes';
import { CardAddressLayout, Description, Specs, Title } from '../headerWithMap.style';
import { ICardAddressProps } from '../headerWithMap.models';
import { CheckMark } from './checkMark';

export const CardAddress = ({ title, description, specs, isMobile }: ICardAddressProps) => {
  const [pDescription] = purify([description]);
  const purifyListElement = (element: string) => purify([element])[0];
  return (
    <CardAddressLayout>
      <Title>{title}</Title>
      <Description data-cs-mask="" dangerouslySetInnerHTML={{ __html: pDescription || '' }} />
      {!isMobile && (
        <Specs>
          {specs &&
            specs.length > 0 &&
            Children.toArray(
              specs?.map((spec) =>
                spec?.value ? (
                  <li>
                    <CheckMark />
                    <span
                      dangerouslySetInnerHTML={{ __html: purifyListElement(spec.value) || '' }}
                    />
                  </li>
                ) : null
              )
            )}
        </Specs>
      )}
    </CardAddressLayout>
  );
};

